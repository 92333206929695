export enum Actions
{
    RestoreAuth,
    SetRssId,
    StartLogIn,
    LogIn,
    LogOut,
    AddMessage,
    DismissMessage
}

export interface RestoreAuthAction 
{
    type: typeof Actions.RestoreAuth;
    loggedIn: boolean;
    username: string | undefined;
    token: string | undefined;
    rssid: string | undefined;
}

export function restoreAuth(loggedIn:boolean, username: string | undefined, token: string | undefined, rssid: string | undefined) : RestoreAuthAction
{
    return {
        type: Actions.RestoreAuth,
        loggedIn,
        username,
        token,
        rssid
    };
}

export interface SetRssIdAction 
{
    type: typeof Actions.SetRssId;
    rssid: string;
}

export function setRssId(rssid: string) : SetRssIdAction
{
    return {
        type: Actions.SetRssId,
        rssid
    };
}

export interface StartLogInAction 
{
    type: typeof Actions.StartLogIn;
    username: string;
}

export function startLogIn(username: string) : StartLogInAction
{
    return {
        type: Actions.StartLogIn,
        username
    };
}

export interface LogInAction 
{
    type: typeof Actions.LogIn;
    username: string;
    token: string;
    rssid: string;
}

export function logIn(username: string, token: string, rssid: string) : LogInAction
{
    return {
        type: Actions.LogIn,
        username,
        token,
        rssid
    };
}

export interface LogOutAction
{
    type: typeof Actions.LogOut;
}

export function logOut() : LogOutAction
{
    return {
        type: Actions.LogOut
    }
}

export interface AddMessageAction
{
    type: typeof Actions.AddMessage;
    message: string;
}

export function addMessage(message: string) : AddMessageAction
{
    return {
        type: Actions.AddMessage,
        message
    }
}

export interface DismissMessageAction
{
    type: typeof Actions.DismissMessage;
    index: number;
}

export function dismissMessage(index: number) : DismissMessageAction
{
    return {
        type: Actions.DismissMessage,
        index
    }
}

export type AuthenticationAction = RestoreAuthAction | SetRssIdAction | StartLogInAction | LogInAction | LogOutAction;
export type MessageAction = AddMessageAction | DismissMessageAction;
export type AnyAction = AuthenticationAction | MessageAction;
