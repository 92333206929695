import React from 'react';
import {Dispatch} from 'redux';
import {Provider, connect} from 'react-redux';
import {PersistGate} from 'redux-persist/lib/integration/react';

import * as server from './server';
import * as actions from './actions';
import {store, persistor, SystemState, AuthenticationStatus, Message} from './reducer';

import 'bootstrap/dist/css/bootstrap.min.css';

import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

interface ChangeRSSDialogProps
{
  show: boolean;
  onConfirm: () => void,
  onCancel: () => void
}

function ChangeRSSDialog(props: ChangeRSSDialogProps)
{
  return (
    <Modal show={props.show} onHide={props.onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Change RSS Feed</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        This will change the RSS link. You will need to update your Podcast player app.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onCancel}>
          Cancel
          </Button>
        <Button variant="primary" onClick={props.onConfirm}>
          Yes! Change the Link
          </Button>
      </Modal.Footer>
    </Modal>
  );
}

interface AlertsProps
{
  messages: Message[];
  onDismiss: (i:number) => void
};

function Alerts(props: AlertsProps)
{
  return (
    <Container>
      {
        props.messages.map((m, i) => (
          <Alert
            id={`${m.id}`}
            key={i}
            variant="warning"
            dismissible
            onClose={ ()=>props.onDismiss(i) }
          >
            {m.text}
          </Alert>
        ))
      }
    </Container>
  );
}

const AlertsContainer = connect( 
  (state:SystemState) => ({
    messages: state.message.messages
  }), 
  (dispatch: Dispatch<actions.AnyAction>) => ({
    onDismiss: (i:number) => {
      dispatch( actions.dismissMessage(i) )
    }
  })
)(Alerts);


interface NavBarProps
{
  userid: string;
  rssid: string;
  token: string
  onLogout: () => void;
  buttonText: string;
  onButtonClicked: () => void;
}

function NavBar(props: NavBarProps) 
{
  const [modal, setModal] = React.useState(false);

  function onChangeRSS()
  {
    setModal(true);
  }

  function onCancelChangeRSS()
  {
    setModal(false);
  }

  function onConfirmChangeRSS()
  {
    setModal(false);
    server.changeRSS(props.token);
  }

  return (
    <Navbar bg="dark" sticky="top">
      <ChangeRSSDialog 
        show={modal}
        onConfirm={onConfirmChangeRSS}
        onCancel={onCancelChangeRSS}
      />
      <Navbar.Brand>
        <Nav.Link href={`/rss/${props.rssid}`}>
          <img
            src="/static/logo.png"
            width="30"
            height="30"
            className="d-inline-block align-top"
            alt="RSS"
          />
        </Nav.Link>
      </Navbar.Brand>
      <Nav.Link onClick={props.onButtonClicked}>{props.buttonText}</Nav.Link>
      <Nav.Link onClick={onChangeRSS}>Change RSS</Nav.Link>
      <Navbar.Collapse className="justify-content-end">

        <Nav.Link onClick={props.onLogout}>Logout</Nav.Link>
      </Navbar.Collapse>
    </Navbar>
  );
}

const NavBarContainer = connect( 
  (state:SystemState) => ({
    userid: state.auth.username as string,
    rssid: state.auth.rssid as string,
    token: state.auth.token as string
  }), 
  (dispatch: Dispatch<actions.AnyAction>) => ({
    onLogout: ()=>{
      server.logOut(dispatch)
    }
  })
)(NavBar);

interface LoginFormProps 
{
  isLoggingIn: boolean;
  messages: string[];
  onLogin: (userid: string, password: string) => void;
};

function LoginForm(props: LoginFormProps) 
{
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');

  function handleSubmit(event: any)
  {
    props.onLogin(username, password);
    event.preventDefault();
  }

  return (
    <div className="center-body">
      <Form className="form-signin" onSubmit={handleSubmit}>
        <Form.Group>

          <Form.Control
            id="formUsername"
            disabled={props.isLoggingIn}
            required
            className="form-control"
            type="username"
            placeholder="Username"
            value={username}
            onChange={ (e) => setUsername(e.target.value) }
            />
          
          <Form.Control
            id="formPassword"
            disabled={props.isLoggingIn}
            required
            className="form-control"
            type="password"
            placeholder="Password"
            value={password}
            onChange={ (e) => setPassword(e.target.value) }
            />

          <Button
            disabled={props.isLoggingIn}
            className="form-control"
            variant="primary"
            type="submit"
            >
              { !props.isLoggingIn && "Log In" }
              { props.isLoggingIn && 
                <Spinner 
                  size="sm"
                  animation="border"
                  role="status"
                  />
              }
          </Button>
        </Form.Group>          
      </Form>
    </div>
  );
}

interface RegisterFormProps 
{
  token: string;
};

function RegisterForm(props: RegisterFormProps) 
{
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');

  function handleSubmit(event: any)
  {
    server.register(props.token, username, password);
    event.preventDefault();
  }

  return (
    <div className="center-body">
      <Form className="form-signin" onSubmit={handleSubmit}>
        <Form.Group>

          <Form.Control
            id="registerUsername"
            required
            className="form-control"
            type="username"
            placeholder="Username"
            value={username}
            onChange={ (e) => setUsername(e.target.value) }
            />
          
          <Form.Control
            id="registerPassword"
            required
            className="form-control"
            type="password"
            placeholder="Password"
            value={password}
            onChange={ (e) => setPassword(e.target.value) }
            />

          <Button
            className="form-control"
            variant="primary"
            type="submit"
            >
              Register User
          </Button>
        </Form.Group>          
      </Form>
    </div>
  );
}

interface AddUrlFormProps {
  token: string
};

function AddUrlForm(props: AddUrlFormProps) 
{
  const [url, setUrl] = React.useState('');

  function handleSubmit(event: any)
  {
    server.submitUrl(props.token, url);
    setUrl('');
    
    event.preventDefault();
    event.stopPropagation();
  }

  return (
    <div className="center-body">
      <Form className="form-signin" onSubmit={handleSubmit}>
        <Form.Group>

          <Form.Control
            required
            id="formUrl"
            className="form-control"
            type="url"
            placeholder="URL"
            value={url}
            onChange={ (e) => setUrl(e.target.value) }
            />

          <Button
            className="form-control"
            variant="primary"
            type="submit"
            >
              Add
          </Button>

        </Form.Group>
      </Form>
    </div>
  );
}

const AddUrlFormContainer = connect( 
  (state:SystemState) => ({
    token: state.auth.token as string
  }), 
  (dispatch: Dispatch<actions.AnyAction>) => ({
  })
)(AddUrlForm);

const RegisterFormContainer = connect( 
  (state:SystemState) => ({
    token: state.auth.token as string
  }), 
  (dispatch: Dispatch<actions.AnyAction>) => ({
  })
)(RegisterForm);

function Loading(props:any)
{
  return (
    <div className="loading">
      <Spinner
        animation="border"
        role="status"
      />
    </div>
  );
}

const LoginFormContainer = connect( 
  (state:SystemState) => ({
    isLoggingIn: state.auth.state === AuthenticationStatus.LogginIn,
    messages: []
  }), 
  (dispatch: Dispatch<actions.AnyAction>) => ({
    onLogin: (username:string, password:string) => server.logIn(dispatch, username, password)
  })
)(LoginForm);



interface SelectProps
{
  selection: number;
}

const Select: React.FunctionComponent<SelectProps> = ({selection, children}) =>
  <React.Fragment>
    { React.Children.toArray(children).filter( (c, i) => i===selection ) }
  </React.Fragment>

function LoggedIn()
{
  const [add, setAdd] = React.useState(true);

  return (
    <React.Fragment>
      <NavBarContainer
        buttonText={add ? "Register" : "Add URL"}
        onButtonClicked={() => setAdd(!add)}
        />
        <Select selection={add?0:1}>
          <AddUrlFormContainer />
          <RegisterFormContainer />
        </Select>
    </React.Fragment>
  );
}

interface App2Props
{
  authStatus : AuthenticationStatus,
};

function App2(props: App2Props)
{
  if( props.authStatus === AuthenticationStatus.LoggedIn )
  {
    return (
      <LoggedIn />
    );
  }

  if( props.authStatus === AuthenticationStatus.LoggedOut || props.authStatus === AuthenticationStatus.LogginIn )
  {
    return (
      <LoginFormContainer />
    );
  }

  return <Loading />
}

const App2Container = connect( 
  (state:SystemState) => ({
    authStatus: state.auth.state
  }), 
  dispatch => ({
  })
)(App2);

function App() 
{
  return (
    <Provider store={store}>
      <PersistGate loading={<Loading />} persistor={persistor}>
        <App2Container />
        <AlertsContainer />
      </PersistGate>
    </Provider>
  );
}

export default App;
