import * as Redux from 'redux';
import { createReducer } from "@reduxjs/toolkit";

import * as ReduxPersist from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
    Actions,
    LogOutAction,
    RestoreAuthAction,
    SetRssIdAction,
    LogInAction,
    StartLogInAction,
    AddMessageAction,
    DismissMessageAction
} from './actions';

export enum AuthenticationStatus
{
    LoggedOut,
    Restoring,
    LogginIn,
    LoggedIn
}

export interface AuthenticationState
{
    state: AuthenticationStatus;
    username: string | undefined;
    token: string | undefined;
    rssid: string | undefined;
}

const initialAuthenticationState : AuthenticationState = {
    state: AuthenticationStatus.LoggedOut,
    username: undefined,
    token: undefined,
    rssid: undefined
};

export const auth = createReducer(initialAuthenticationState, {

    [Actions.RestoreAuth] : (state, action: RestoreAuthAction) => ({
        state: action.loggedIn ? AuthenticationStatus.LoggedIn : AuthenticationStatus.LoggedOut,
        username: action.username,
        token: action.token,
        rssid: action.rssid
    }),

    [Actions.SetRssId] : (state, action: SetRssIdAction) => {
        state.rssid = action.rssid
    },

    [Actions.StartLogIn] : (state, action: StartLogInAction) => {
        return {
            state: AuthenticationStatus.LogginIn,
            username: action.username,
            token: undefined,
            rssid: undefined
        };
    },

    [Actions.LogIn] : (state, action: LogInAction) => ({
            state: AuthenticationStatus.LoggedIn,
            username: action.username,
            token: action.token,
            rssid: action.rssid
        }),

    [Actions.LogOut] : (state, action: LogOutAction) => ({ 
            state: AuthenticationStatus.LoggedOut,
            username: undefined,
            token: undefined,
            rssid: undefined
        })
});

export interface Message
{
    id: number;
    text: string;
}

export interface MessageState
{
    nextMessage: number;
    messages: Message[];
}

const initialMessageState : MessageState = {
    nextMessage: 0,
    messages: []
};

export const message = createReducer(initialMessageState, {

    [Actions.AddMessage] : (state, action: AddMessageAction) => {
        state.messages.push({
            id: state.nextMessage,
            text: action.message
        });
        state.nextMessage++;
    },
    
    [Actions.DismissMessage] : (state, action: DismissMessageAction) => {
        state.messages.splice(action.index, 1);
    },
    
    [Actions.LogIn] : (state, action: LogInAction) => {
        state.messages = [];
    },

    [Actions.LogOut] : (state, action: LogOutAction) => {
        state.messages = [];
    }

});

export interface SystemState
{
    auth: AuthenticationState;
    message: MessageState;
}

const rootReducer = Redux.combineReducers({
    auth,
    message
});

const persistConfig = {
    key: 'personalpodcast',
    storage
};

const persistedReducer = ReduxPersist.persistReducer(persistConfig, rootReducer)

export const store = Redux.createStore(persistedReducer);
export const persistor = ReduxPersist.persistStore(store);
