import * as actions from './actions';
import { store } from './reducer';

export async function sendJSON(endpoint:string, data:any)
{
  const url = `${process.env.REACT_APP_ENDPOINT}${endpoint}`;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  });
 
  try
  {
    return await response.json();
  }
  catch(e)
  {
    throw Error("Malformed response.");
  }
}

export async function logIn(dispatch: any, username:string, password:string)
{
  try
  {
    dispatch(actions.startLogIn(username));

    const response = await sendJSON('/auth', {
      userid: username,
      password
    });

    if( response.error )
    {
      throw Error(response.error);
    }

    const token = response.token;
    const rssid = response.rssid;

    dispatch(actions.logIn(username, token, rssid));
  }
  catch(e)
  {
    dispatch(actions.restoreAuth(false, username, undefined, undefined));
    dispatch(actions.addMessage(`Login failed: ${e.message}`));
  }
}

export async function logOut(dispatch: any)
{
  dispatch( actions.logOut() )  
}

export async function submitUrl(token:string, url:string)
{
  try
  {
    const data = await sendJSON('/add', {token, url});

    if( data.error )
    {
      store.dispatch( actions.addMessage(`Failed to add URL: ${data.error}`) );
    }
  }
  catch(e)
  {
    store.dispatch( actions.addMessage(`Failed to add URL: ${e.message}`) );
  }
}

export async function changeRSS(token:string)
{
  try
  {
    const data = await sendJSON('/changerss', {token});
    
    if( data.error )
    {
      store.dispatch( actions.addMessage(`Failed to change RSS: ${data.error}`) );
    }
    
    store.dispatch( actions.setRssId(data.rssid));
  }
  catch(e)
  {
    store.dispatch( actions.addMessage(`Failed to change RSS: ${e.message}`) );
  }
}

export async function register(token:string, username:string, password:string)
{
  try
  {
    const data = await sendJSON('/register', {
      token,
      userid: username,
      password
    });

    if( data.error )
    {
      store.dispatch( actions.addMessage(`failed registering user: ${data.error}`) );
    }
  }
  catch(e)
  {
    store.dispatch( actions.addMessage("failed registering user") );
  }
}
